import React from 'react';
import styled from 'styled-components';

const CTA = styled.img`
  width: 250px;
  animation: pulse 1s infinite;

  @keyframes pulse {
    0% {
      transform: scaleX(1);
    }
    50% {
      transform: scale3d(1.05, 1.05, 1.05);
    }
    to {
      transform: scaleX(1);
    }
  }
`;

const Anchor = styled.a`
  div {
    animation: pulse 2.5s infinite;

    @keyframes pulse {
      0% {
        transform: scaleX(1);
      }
      50% {
        transform: scale3d(1.05, 1.05, 1.05);
      }
      to {
        transform: scaleX(1);
      }
    }
  }
`;

interface ModalProps {
  token: string | null;
}

const Modal: React.FC<ModalProps> = ({ token }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h1>
          Divirta-se com Giros no <strong><br />Fortune Tiger</strong>
        </h1>

        <StyledTable>
          <div className="list-container">
            <div className="title-container">
              <h3>Depósito</h3>
            </div>
            <ul>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1"> R$ 20 </a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2"> R$ 50 </a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1"> R$ 100 </a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2"> R$ 250 </a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1"> R$ 500 </a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2 last-1"> R$ 1.000 </a>
            </ul>
          </div>

          <span className="separator"></span>

          <div className="list-container">
            <div className="title-container">
              <h3>
                Giros no <br />
                <strong>Fortune Tiger</strong>
              </h3>
            </div>
            <ul>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1">03</a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2">06</a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1">15</a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2">25</a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-1">50</a>
              <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`} className="list-2 last-2">80</a>
            </ul>
          </div>
        </StyledTable>

        <div>
          <img
            src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/1c5f3d0c-4b42-4324-10a3-6c081fd95c00/public"
            alt=""
          />
        </div>

        <a href={`https://cpxpage.cc/?_lp=1&_token=${token}`}>
          <CTA src="https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/d294ec32-884f-4c8c-e77e-039c7d5e9700/public" />
        </a>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); // Dark background with 50% opacity
  z-index: 1000;
`;

const ModalContent = styled.div`
  padding: 40px 20px;
  border-radius: 5px;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 105px;
  gap: 25px;
  text-align: center;
  position: relative;
  background-image: url(https://imagedelivery.net/lfSRFuvEHojPT-iMbdh4pw/81ad1bc2-01d5-4033-b35c-c3b59b4af200/public);
  background-size: 100% 100%;

  h1 {
    font-family: 'Gotham-Black';
    color: white;
    font-size: 18px;
  }

  span {
    font-family: 'Gotham-Black';
  }

  @media screen and (min-width: 768px) {
    max-width: 450px;
  }
`;

const StyledTable = styled.div`
  display: flex;
  border: 3px solid #4d90e6;
  border-radius: 1rem;
  background: #0e1d41;
  padding: 10px;
  text-decoration: none;

  .separator {
    display: flex;
    min-width: 3px;
    background: #4d90e6;
    min-height: 80%;
    max-height: 80%;
  }

  .list-container {
    flex: 1;

    .title-container {
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;

      h3 {
        text-align: center;
        color: white;
        font-family: 'Gotham-Black';
        font-weight: normal;
        text-transform: uppercase;
        font-size: 16px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      padding: 0px;

      a {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        list-style: none;
        padding: 0.5rem 0;
        font-family: 'Gotham-Black';
        text-decoration: none;
      }

      .list-1 {
        background: #4d90e6;
        color: #0e1d41;
      }

      .list-2 {
        color: #4d90e6;
      }

      .list-1.last-1 {
        border-bottom-left-radius: 0.7rem;
      }

      .list-1.last-2 {
        border-bottom-right-radius: 0.7rem;
      }
    }
  }
`;
